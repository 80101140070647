<template>
  <div>
    <v-snackbar
      v-for="(alert, index) in alerts"
      :key="alert.id"
      v-model="alert.snackbar"
      class="alert-principal"
      bottom
      multi-line
      right
      :color="snackbarColor(alert.status)"
      :ref="`snackbars${index}`"
      :timeout="alert.timeout"
    >
      <div class="d-flex align-center alert__content">
        <font-awesome-icon
          v-if="shouldShowCheckIcon(alert.status)"
          icon="check"
        />
        <div class="d-flex flex-column content__text">
          <h1>
            {{ alert.title }}
          </h1>
          <p v-if="alert.text">{{ alert.text }}</p>
        </div>
        <v-btn
          class="alert__close-button"
          icon
          text
          @click="closeSnackbar(alert)"
        >
          Ok
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AlertPrincipal',

  data: () => ({
    alerts: [],
    alertsHeight: 0,
    timeout: 10000,
  }),

  created() {
    this.$root.$on('notify', (status, title, text) => {
      this.reset()
      this.singleAlert(status, title, text)
    })
    this.$root.$on('notifyArray', (alerts) => {
      this.reset()
      if (alerts) {
        this.manyAlerts(alerts?.filter(Boolean))
      }
    })
    document.addEventListener('notify', this.callSingleAlert)
  },

  watch: {
    getApiError: {
      handler(error) {
        if (error) this.singleAlert('error', this.$t(error.key))
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('apiManager', ['getApiError']),
  },

  methods: {
    calculateGapBetweenAlerts() {
      this.$nextTick(() => {
        for (let idx = 1; idx < this.alerts.length; idx++) {
          const currentSnackbar = this.$refs[`snackbars${idx}`]
          let currentSnackbarStyle = {}
          if (currentSnackbar[0]) {
            currentSnackbarStyle = currentSnackbar[0].$el.style
          }
          const previousSnackbar = this.$refs[`snackbars${idx - 1}`]
          let previousSnackbarHeight = {}
          if (previousSnackbar[0]) {
            previousSnackbarHeight =
              previousSnackbar[0].$el.children[0].clientHeight + 8
          }
          this.alertsHeight += previousSnackbarHeight
          currentSnackbarStyle.marginBottom = this.alertsHeight + 'px'
        }
      })
    },
    callSingleAlert(event) {
      this.reset()
      const formattedText = this.$t(event.detail.text)
        .replace(/<\/?b>/g, '')
        .replace(/<\/?br>/g, ' ')
      this.singleAlert(event.detail.color, formattedText)
    },
    closeSnackbar(alert) {
      alert.snackbar = false
    },
    manyAlerts(alerts) {
      let uniqueAlerts = this.removeRepeated(alerts)

      let time = this.timeout
      let showtime = 0
      let countSnackbar = 0

      this.alerts = uniqueAlerts.map(function (alert) {
        showtime = showtime + time
        let message = {
          snackbar: true,
          timeout: showtime,
          status: alert.status,
          title: alert.message,
          id: countSnackbar,
        }
        countSnackbar++
        return message
      })
      this.calculateGapBetweenAlerts()
    },
    removeRepeated(alerts) {
      let result = []
      for (var x = 0; x < alerts.length; x++) {
        if (!result.find((a) => a.message === alerts[x].message))
          result.push(alerts[x])
      }
      return result
    },
    reset() {
      this.alerts = []
      this.alertsHeight = 0
    },
    shouldShowCheckIcon(status) {
      return status === 'success'
    },
    singleAlert(status, title, text = '') {
      let alert = {
        snackbar: true,
        timeout: this.timeout,
        status,
        title,
        text,
      }
      this.alerts.push(alert)
    },
    snackbarColor(status) {
      const statusColors = {
        success: '#39AF49',
        warning: '#F2CD12',
        error: '#F23528',
        info: '#4A76BC',
        system: '#1A2B46',
      }
      return statusColors[status]
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-principal {
  padding: 12px;

  .alert__content {
    gap: 12px;

    .content__text {
      flex: 1 1 auto;
      gap: 4px;

      h1 {
        color: #fff;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        word-break: break-word;
      }
      p {
        color: #fff;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        word-break: break-word;
      }
    }
    .alert__close-button {
      color: #fff;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
::v-deep .v-snack__wrapper {
  border-radius: 8px !important;
  box-shadow: none !important;
  margin: 0px;
}
</style>
